import { render, staticRenderFns } from "./show-users.vue?vue&type=template&id=1b3e962e"
import script from "./show-users.vue?vue&type=script&lang=js"
export * from "./show-users.vue?vue&type=script&lang=js"
import style0 from "./show-users.vue?vue&type=style&index=0&id=1b3e962e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports